<template>
  <div id="weatherContant">
    <div class="headPart">
      <p id="header_title" :style="{'font-size':bigTitleSize}">{{ nameScreen }}</p>

      <div class="link-btn">
        <span class="link-btn-item" v-for="(item,index) in linkList" :key="index"
              @click="handleLink(item.url)">{{ item.text }}
        </span>
      </div>

    </div>
    <!--    <div class="middle">
          <div class="el_select">
            <el-select @change="baseChange" class="select" v-model="baseActiveName" placeholder="请选择">
              <el-option v-for="item in baseListOption" :key="item.id" :label="item.baseName" :value="item.id"></el-option>
            </el-select>
          </div>
          <div class="el_select2">
            <el-select @change="deviceChange" class="select" v-model="deviceAcitvName" placeholder="请选择">
              <el-option v-for="item in deviceListOption" :key="item.deviceNum" :label="item.deviceName"
                         :value="item.deviceNum"></el-option>
            </el-select>
          </div>
        </div>-->
<!--    <div class="el_select">
      <el-select @change="baseChange" class="select" v-model="baseActiveName" placeholder="请选择">
        <el-option v-for="item in baseListOption" :key="item.id" :label="item.baseName" :value="item.id"></el-option>
      </el-select>
    </div>-->
<!--    <div class="el_select2">
      <el-select @change="deviceChange" class="select" v-model="deviceAcitvName" placeholder="请选择">
        <el-option v-for="item in deviceListOption" :key="item.deviceNum" :label="item.deviceName"
                   :value="item.deviceNum"></el-option>
      </el-select>
    </div>-->
    <div class="btns">
      <span  v-for="item in deviceListOption" :key="item.deviceNum" :label="item.deviceName" @click="deviceChange(item.deviceNum)">{{item.deviceName}}</span>

    </div>
    <div class="bodyPart">
      <div class="bodyTop">
        <div class="mainBox">
          <div class="boxTitle">实时数据</div>
          <!-- <div id="echarts1"></div> -->
          <div class="itemMian">

            <ul>
              <li v-for="item, index in realTimeData" :key="index">
                <p :title="item.data">{{ item.data }}</p>
                <p class="itemTxt" :title="item.type">{{ item.type }}</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="mainBox">
          <div class="boxTitle">{{ data1 }}曲线</div>
          <div id="echarts2"></div>
        </div>
        <div class="mainBox">
          <div class="boxTitle">{{ data2 }}曲线</div>
          <div id="echarts3"></div>
        </div>
        <div class="mainBox">
          <div class="boxTitle">{{ data7 }}曲线</div>
          <div id="echarts7"></div>
        </div>

      </div>
      <div class="bodyBottom">
        <div class="mainBox">
          <div class="boxTitle">{{ data3 }}曲线</div>
          <div id="echarts4"></div>
        </div>
        <div class="mainBox">
          <div class="boxTitle">{{ data4 }}曲线</div>
          <div id="echarts5"></div>
        </div>
        <div class="mainBox">
          <div class="boxTitle">{{ data5 }}曲线</div>
          <div id="echarts6"></div>
        </div>
        <div class="mainBox">
          <div class="boxTitle">报警记录</div>
          <!-- <el-table :data="tableData" stripe style="width: 100%">
            <el-table-column prop="alarmTime" label="时间" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="name" label="内容" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="value" label="触发值">
            </el-table-column>
          </el-table> -->
          <div class="alarmBox">
            <ul class="alarmUl">
              <li>时间</li>
              <li>内容</li>
              <li>触发值</li>
            </ul>
            <div class="alarmItem">
              <ul class="itemUl" v-for="item in tableData" :key="item.id">
                <li>{{ item.createTime }}</li>
                <li class="color1">{{ item.sensorName }}</li>
                <li class="color2">{{ item.warnData }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echartsLine from "@/components/oneScreenLine.vue";
import * as echarts from "echarts";

export default {
  components: {
    // screenMap,
    echartsLine,
  },
  data() {
    return {
      nameScreen: "",
      dataSHow: true,
      linkList: [{
        text: '智慧园区',
        url: '/cloud2'
      }, {
        text: '智慧工厂',
        url: '/factory'
      }, {
        text: '研究院',
        url: '/yanjiuyuan'
      }, {
        text: '产业大数据',
        url: '/chanye'
      }, {
        text: '保鲜系统',
        url: '/baoxian'
      }, {
        text: '返回首页',
        url: '/xunyangNav'
      }],
      chartBox: ['soilTempHum', 'soilPhEle', 'TempHum', 'pressure', 'windSpeed', 'sunshine', 'co2', 'rain'],
      nowDeviceNumber: '',
      canalToggleBth: [], // '日', '月', '季', '半年', '年'
      weatherInfoAlarm: {},
      weatherInfo: {},
      tableData: [],
      deviceField: [],
      dateList: [],
      todayArr: [],
      currentDevice: null,
      timer: null,
      date: null,
      weatherKey: "99b1ab7c36d349189f448b53b6d6b959",
      weatherSeven: [],
      newWarningInfo: [],
      categoryAir: "",
      realTimeAir: {},
      accTempValue: "",
      options: [{name: "2022", value: "2022"}],
      baseListOption: [],//所有基地及绑定设备信息
      baseActiveName: "",
      deviceListOption: [],
      deviceAcitvName: "",
      realTimeMeteoro: [],
      tempList: [],
      illuminationList: [],
      atmosphericList: [],
      windSpeedList: [],
      yearTemp: null,
      recentTimeAll: [],
      deviceNumbers: '',
      allDevice: [],//所有设备信息  {deviceNumber:data}
      realTimeData: [],
      dataList: [],
      data1: '',
      data2: '',
      data3: '',
      data4: '',
      data5: '',
      data7: '',
      city: '',
      county: '',
      bigTitleSize: ''
    };
  },
  mounted() {
    this.getPowerName();
    this.getAllData();
    this.getBaseList(0);

    // this.getEcharts1();
    // this.getEcharts2();
    // this.getEcharts3();
    // this.getEcharts4();
    // this.getEcharts5();
    // this.getEcharts6();
  },
  methods: {
    handleLink(url) {
      this.$router.push(url);
    },
    getPowerName() {
      this.$get("/largeScreenManagement/getLargeScreenManagements", {
        page: 1,
        size: 100
      }).then((res) => {

        if (res.data.state == 'success') {
          this.city = res.data.datas[0].city;
          this.county = res.data.datas[0].county;
          this.nameScreen = res.data.datas[0].name;

        }
      })
    },
    resizeBigTitle() {
      let titLength = this.nameScreen.length;
      if (titLength <= 8) {
        this.bigTitleSize = "40px";
      } else if (titLength <= 10) {
        this.bigTitleSize = "38px";

      } else if (titLength <= 12) {
        this.bigTitleSize = "36px";

      } else {
        this.bigTitleSize = "26px";

      }
    },
    backIndex() {
      this.$router.push(window.localStorage.getItem('screenRouter'));
    },
    //获取报警记录
    getWarnList(deviceNumber) {
      this.$get("/warnManage/pageWarnLogs", {
        page: 1,
        size: 1000,
        deviceNum: deviceNumber,
      }).then((res) => {
        if (res.data.state == "success") {
          let m = res.data.datas;
          this.tableData = m;
        }
      });
    },

    //基地变更
    baseChange(val) {
      this.deviceListOption = []
      console.log("所有基地", this.baseListOption);
      let baseIndex = this.baseListOption.findIndex(option => option.id == val);
      let base = this.baseListOption[baseIndex];
      console.log("当前基地", base);
      this.baseActiveName = base.baseName;//当前基地名称
      let deviceList = JSON.parse(base.weatherDevice);//基地关联设备下拉列表  设备号列表，无设备信息
      console.log(deviceList)

      if (deviceList.length > 0) {
        for (let i = 0; i < deviceList.length; i++) {
          this.deviceListOption.push(this.allDevice[deviceList[i]])
        }
        console.log("设备下拉：", this.deviceListOption)


        this.deviceChange(this.deviceListOption[0].deviceNum)//第一个设备下拉
      } else {
        this.$message.info("基地没有绑定气象设备")
      }

    },
    deviceChange(val) {
      let i = this.deviceListOption.findIndex(option => option.deviceNum == val);
      this.realTimeData = [];
      this.deviceAcitvName = this.deviceListOption[i].deviceName;
      if (this.deviceListOption[i].realData) {
        let dataAll = this.deviceListOption[i].realData.replace(/null\|/g, '').replace(/\|null/g, '').split("|");
        let type = [];
        let data = [];
        for (let i = 0; i < dataAll.length; i++) {
          type.push(dataAll[i].split("·")[0]);
          data.push(dataAll[i].split("·")[1]);
        }
        for (let x = 0; x < type.length; x++) {
          this.realTimeData.push({type: type[x], data: data[x]})
        }
        //console.log(this.realTimeData);
        this.getDeviceData(val);
        this.getWarnList(val);
      }
    },

    // 获取基地
    getBaseList() {
      this.$get("/baseManagement/getBaseManagements", {
        page: 1,
        size: 100
      }).then((res) => {
        if (res.data.state == 'success') {
          let datas = res.data.datas;
          this.baseListOption = datas;


          this.baseChange(datas[0].id)


        } else {
          console.log(res)
        }
      })
    },
    // 获取设备信息
    getAllData() {
      this.$get('/deviceManage/getDeviceInfos', {page: 1, size: 10000}).then((res) => {
        if (res.data.state == 'success') {
          res.data.datas.forEach(item => {
            const d = item.deviceNum
            this.allDevice[d] = item;
          });
        }
      })
    },
    // 设备曲线数据
    getDeviceData(dev) {
      this.$get("/dptj/cgqqx4day", {
        deviceNum: dev
      }).then((res) => {
        if (res.data.state == 'success') {
          this.dataList = [];
          let datas = res.data.data;
          if (datas.length != 0) {
            for (let a = 0; a < datas.length; a++) {
              if (datas[a].sensorName != "null") {
                this.dataList.push(datas[a]);
              }

            }
            console.log("曲线", this.dataList)
            this.data1 = this.dataList[10].sensorName;
            this.data2 = this.dataList[16].sensorName;
            this.data3 = this.dataList[14].sensorName;
            this.data4 = this.dataList[3].sensorName;
            this.data5 = this.dataList[4].sensorName;
            this.data7 = this.dataList[5].sensorName;
            let time1 = this.dataList[10].times.map(item => {
              return item.substring(11) + "时"
            });
            let value1 = this.dataList[10].values;
            let time2 = this.dataList[16].times.map(item => {
              return item.substring(11) + "时"
            });
            ;
            let value2 = this.dataList[16].values;
            let time3 = this.dataList[14].times.map(item => {
              return item.substring(11) + "时"
            });
            ;
            let value3 = this.dataList[14].values;
            let time4 = this.dataList[3].times.map(item => {
              return item.substring(11) + "时"
            });
            ;
            let value4 = this.dataList[3].values;
            let time5 = this.dataList[4].times.map(item => {
              return item.substring(11) + "时"
            });
            ;
            let value5 = this.dataList[4].values;
            let time7 = this.dataList[5].times.map(item => {
              return item.substring(11) + "时"
            });
            ;
            let value7 = this.dataList[5].values;
            this.getEcharts2(time1, value1, "单位：Lux");
            this.getEcharts3(time2, value2, "单位：ppm");
            this.getEcharts4(time3, value3, "单位：mm");
            this.getEcharts5(time4, value4, "单位：℃");
            this.getEcharts6(time5, value5, "单位：%RH");
            this.getEcharts7(time7, value7, "单位：pH");
          }
        }
      })
    },

    getEcharts1() {
      var chartDom = document.getElementById("echarts1");
      var myChart = echarts.init(chartDom);
      let option = {
        grid: {
          containLabel: true,
          left: 30,
          top: 20,
          bottom: 20,
          right: 30
        },
        tooltip: {
          show: true,
          trigger: 'axis',
        },
        xAxis: [{
          type: 'category',
          data: ['01/08', '01/09', '01/10', '01/11', '01/12', '01/13', '01/14'],
          axisLine: {
            lineStyle: {
              color: '#96A9B7',
              type: 'dashed'//虚线
            }
          },
          axisTick: {
            show: false
          },

        }],
        yAxis: [{
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#96A9B7'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#96A9B7',
              type: 'dashed'//虚线
            }
          }
        }],
        series: [{
          type: 'line',
          data: [10, 10, 30, 12, 15, 3, 7],
          smooth: true,
          yAxisIndex: 0,
          symbol: 'circle',
          symbolSize: 6,
          showSymbol: false,
          hoverAnimation: true,
          itemStyle: {
            color: "#fff",
            borderColor: "#029cfc",
            borderWidth: 2,
          },
          lineStyle: {
            normal: {
              borderWidth: 5,
              color: '#029cfc'
            }
          },
          areaStyle: { //区域填充样式
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: "#029cfc"
              },
                {
                  offset: 1,
                  color: "rgba(0, 255, 255, 0)"
                }
              ], false),
              shadowColor: '#029cfc', //阴影颜色
              shadowBlur: 20
            }
          },
        }
        ]
      };

      option && myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    getEcharts2(time, value, name) {
      var chartDom = document.getElementById("echarts2");
      var myChart = echarts.init(chartDom);
      let option = {
        grid: {
          containLabel: true,
          left: 30,
          top: 30,
          bottom: 20,
          right: 30
        },
        tooltip: {
          show: true,
          trigger: 'axis',
        },
        xAxis: [{
          type: 'category',
          // data: ['01/08', '01/09', '01/10', '01/11', '01/12', '01/13', '01/14'],
          data: time,
          axisLine: {
            lineStyle: {
              color: '#96A9B7',
              type: 'dashed'//虚线
            }
          },
          axisTick: {
            show: false
          },

        }],
        yAxis: [{
          type: 'value',
          name: name,
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#96A9B7'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#96A9B7',
              type: 'dashed'//虚线
            }
          }
        }],
        series: [{
          type: 'line',
          // data: [10, 10, 30, 12, 15, 3, 7],
          data: value,
          smooth: true,
          yAxisIndex: 0,
          symbol: 'circle',
          symbolSize: 6,
          showSymbol: false,
          hoverAnimation: true,
          itemStyle: {
            color: "#fff",
            borderColor: "#029cfc",
            borderWidth: 2,
          },
          lineStyle: {
            normal: {
              borderWidth: 5,
              color: '#029cfc'
            }
          },
          areaStyle: { //区域填充样式
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: "#029cfc"
              },
                {
                  offset: 1,
                  color: "rgba(0, 255, 255, 0)"
                }
              ], false),
              shadowColor: '#029cfc', //阴影颜色
              shadowBlur: 20
            }
          },
        }
        ]
      };

      option && myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    getEcharts3(time, value, name) {
      var chartDom = document.getElementById("echarts3");
      var myChart = echarts.init(chartDom);
      let option = {
        grid: {
          containLabel: true,
          left: 30,
          top: 30,
          bottom: 20,
          right: 30
        },
        tooltip: {
          show: true,
          trigger: 'axis',
        },
        xAxis: [{
          type: 'category',
          // data: ['01/08', '01/09', '01/10', '01/11', '01/12', '01/13', '01/14'],
          data: time,
          axisLine: {
            lineStyle: {
              color: '#96A9B7',
              type: 'dashed'//虚线
            }
          },
          axisTick: {
            show: false
          },

        }],
        yAxis: [{
          type: 'value',
          name: name,
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#96A9B7'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#96A9B7',
              type: 'dashed'//虚线
            }
          }
        }],
        series: [{
          type: 'line',
          // data: [10, 10, 30, 12, 15, 3, 7],
          data: value,
          smooth: true,
          yAxisIndex: 0,
          symbol: 'circle',
          symbolSize: 6,
          showSymbol: false,
          hoverAnimation: true,
          itemStyle: {
            color: "#fff",
            borderColor: "#029cfc",
            borderWidth: 2,
          },
          lineStyle: {
            normal: {
              borderWidth: 5,
              color: '#029cfc'
            }
          },
          areaStyle: { //区域填充样式
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: "#029cfc"
              },
                {
                  offset: 1,
                  color: "rgba(0, 255, 255, 0)"
                }
              ], false),
              shadowColor: '#029cfc', //阴影颜色
              shadowBlur: 20
            }
          },
        }
        ]
      };

      option && myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    getEcharts4(time, value, name) {
      var chartDom = document.getElementById("echarts4");
      var myChart = echarts.init(chartDom);
      let option = {
        grid: {
          containLabel: true,
          left: 30,
          top: 30,
          bottom: 20,
          right: 30
        },
        tooltip: {
          show: true,
          trigger: 'axis',
        },
        xAxis: [{
          type: 'category',
          // data: ['01/08', '01/09', '01/10', '01/11', '01/12', '01/13', '01/14'],
          data: time,
          axisLine: {
            lineStyle: {
              color: '#96A9B7',
              type: 'dashed'//虚线
            }
          },
          axisTick: {
            show: false
          },

        }],
        yAxis: [{
          type: 'value',
          name: name,
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#96A9B7'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#96A9B7',
              type: 'dashed'//虚线
            }
          }
        }],
        series: [{
          type: 'line',
          // data: [10, 10, 30, 12, 15, 3, 7],
          data: value,
          smooth: true,
          yAxisIndex: 0,
          symbol: 'circle',
          symbolSize: 6,
          showSymbol: false,
          hoverAnimation: true,
          itemStyle: {
            color: "#fff",
            borderColor: "#029cfc",
            borderWidth: 2,
          },
          lineStyle: {
            normal: {
              borderWidth: 5,
              color: '#029cfc'
            }
          },
          areaStyle: { //区域填充样式
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: "#029cfc"
              },
                {
                  offset: 1,
                  color: "rgba(0, 255, 255, 0)"
                }
              ], false),
              shadowColor: '#029cfc', //阴影颜色
              shadowBlur: 20
            }
          },
        }
        ]
      };

      option && myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    getEcharts5(time, value, name) {
      var chartDom = document.getElementById("echarts5");
      var myChart = echarts.init(chartDom);
      let option = {
        grid: {
          containLabel: true,
          left: 30,
          top: 30,
          bottom: 20,
          right: 30
        },
        tooltip: {
          show: true,
          trigger: 'axis',
        },
        xAxis: [{
          type: 'category',
          // data: ['01/08', '01/09', '01/10', '01/11', '01/12', '01/13', '01/14'],
          data: time,
          axisLine: {
            lineStyle: {
              color: '#96A9B7',
              type: 'dashed'//虚线
            }
          },
          axisTick: {
            show: false
          },

        }],
        yAxis: [{
          type: 'value',
          name: name,
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#96A9B7'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#96A9B7',
              type: 'dashed'//虚线
            }
          }
        }],
        series: [{
          type: 'line',
          data: [10, 10, 30, 12, 15, 3, 7],
          data: value,
          smooth: true,
          yAxisIndex: 0,
          symbol: 'circle',
          symbolSize: 6,
          showSymbol: false,
          hoverAnimation: true,
          itemStyle: {
            color: "#fff",
            borderColor: "#029cfc",
            borderWidth: 2,
          },
          lineStyle: {
            normal: {
              borderWidth: 5,
              color: '#029cfc'
            }
          },
          areaStyle: { //区域填充样式
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: "#029cfc"
              },
                {
                  offset: 1,
                  color: "rgba(0, 255, 255, 0)"
                }
              ], false),
              shadowColor: '#029cfc', //阴影颜色
              shadowBlur: 20
            }
          },
        }
        ]
      };

      option && myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    getEcharts6(time, value, name) {
      var chartDom = document.getElementById("echarts6");
      var myChart = echarts.init(chartDom);
      let option = {
        grid: {
          containLabel: true,
          left: 30,
          top: 30,
          bottom: 20,
          right: 30
        },
        tooltip: {
          show: true,
          trigger: 'axis',
        },
        xAxis: [{
          type: 'category',
          // data: ['01/08', '01/09', '01/10', '01/11', '01/12', '01/13', '01/14'],
          data: time,
          axisLine: {
            lineStyle: {
              color: '#96A9B7',
              type: 'dashed'//虚线
            }
          },
          axisTick: {
            show: false
          },

        }],
        yAxis: [{
          type: 'value',
          name: name,
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#96A9B7'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#96A9B7',
              type: 'dashed'//虚线
            }
          }
        }],
        series: [{
          type: 'line',
          // data: [10, 10, 30, 12, 15, 3, 7],
          data: value,
          smooth: true,
          yAxisIndex: 0,
          symbol: 'circle',
          symbolSize: 6,
          showSymbol: false,
          hoverAnimation: true,
          itemStyle: {
            color: "#fff",
            borderColor: "#029cfc",
            borderWidth: 2,
          },
          lineStyle: {
            normal: {
              borderWidth: 5,
              color: '#029cfc'
            }
          },
          areaStyle: { //区域填充样式
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: "#029cfc"
              },
                {
                  offset: 1,
                  color: "rgba(0, 255, 255, 0)"
                }
              ], false),
              shadowColor: 'rgb(2,156,252)', //阴影颜色
              shadowBlur: 20
            }
          },
        }
        ]
      };

      option && myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    getEcharts7(time, value, name) {
      var chartDom = document.getElementById("echarts7");
      var myChart = echarts.init(chartDom);
      let option = {
        grid: {
          containLabel: true,
          left: 30,
          top: 30,
          bottom: 20,
          right: 30
        },
        tooltip: {
          show: true,
          trigger: 'axis',
        },
        xAxis: [{
          type: 'category',
          // data: ['01/08', '01/09', '01/10', '01/11', '01/12', '01/13', '01/14'],
          data: time,
          axisLine: {
            lineStyle: {
              color: '#96A9B7',
              type: 'dashed'//虚线
            }
          },
          axisTick: {
            show: false
          },

        }],
        yAxis: [{
          type: 'value',
          name: name,
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#96A9B7'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#96A9B7',
              type: 'dashed'//虚线
            }
          }
        }],
        series: [{
          type: 'line',
          // data: [10, 10, 30, 12, 15, 3, 7],
          data: value,
          smooth: true,
          yAxisIndex: 0,
          symbol: 'circle',
          symbolSize: 6,
          showSymbol: false,
          hoverAnimation: true,
          itemStyle: {
            color: "#fff",
            borderColor: "#029cfc",
            borderWidth: 2,
          },
          lineStyle: {
            normal: {
              borderWidth: 5,
              color: '#029cfc'
            }
          },
          areaStyle: { //区域填充样式
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: "#029cfc"
              },
                {
                  offset: 1,
                  color: "rgba(0, 255, 255, 0)"
                }
              ], false),
              shadowColor: '#029cfc', //阴影颜色
              shadowBlur: 20
            }
          },
        }
        ]
      };

      option && myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },

  },
};
</script>

<style lang="less" scoped>
* {
  padding: 0;
  margin: 0;
  color: #fff;
}

#weatherContant {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  background-image: url(~@/assets/cloud2/bg.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  //pointer-events: none;
  .headPart {
    width: 100%;
    height: 90px;
    text-align: center;
    overflow: hidden;
    z-index: 45;
    background-image: url(~@/assets/cloud2/index/img.png);
    background-size: 900px;
    background-position: center top;
    background-repeat: no-repeat;

    .headerLeft {
      position: absolute;
      width: 12vh;
      height: 5vh;
      line-height: 5vh;
      top: 3vh;
      left: 4vh;
      font-size: 1.7vh;
      color: #fff;
      cursor: pointer;
    }

    .headerLeft img {
      width: 100%;
      height: 100%;
    }

    // span {
    //   position: absolute;
    //   top: 5vh;
    //   font-size: 2.22vh;
    //   font-weight: 400;
    //   color: #dbe5fa;
    //   text-shadow: 0px 2px 2px rgba(10, 68, 134, 0);
    //   background: linear-gradient(0deg, #7ed7fb 0%, #ffffff 100%);
    //   -webkit-background-clip: text;
    //   -webkit-text-fill-color: transparent;
    //   font-family: customBlack;
    // }
    .btn1 {
      left: 1.46vw;
    }

    .btn2 {
      right: 1.46vw;
    }

    .imgBut {
      position: absolute;
      top: 5vh;
      left: 1.46vw;
      max-width: 35px;
    }

    p {
      font-size: 40px;
      background: linear-gradient(to bottom, #fff 50%, #b8f9fd 50%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: "Title";
      letter-spacing: 4px;
      /*font-style: italic;*/
      line-height: 85px;
    }

    .date {
      position: absolute;
      top: 30%;
      left: 1%;
      font-size: 14px;
      width: 170px;

      .sun {
        width: 24px;
        height: 24px;
        vertical-align: bottom;
      }

      span {
        margin-left: 10px;
      }
    }

    .weather {
      position: absolute;
      top: 34%;
      z-index: 99;
      right: 17.3%;
      font-size: 14px;
      width: 170px;
      height: 40px;

      span {
        margin-right: 10px;
        float: left;
      }
    }

    .user {
      position: absolute;
      top: 30%;
      z-index: 99;
      right: 3%;
      font-size: 14px;
      width: 170px;
      height: 40px;
      cursor: pointer;

      img {
        width: 30px;
        height: 30px;
      }

      span {
        position: relative;
        top: -8px;
        left: 6px;
      }
    }

    .backs {
      position: absolute;
      top: 34%;
      right: 3%;
      font-size: 16px;
      cursor: pointer;
      font-family: "mokuaiTitile";
      background: linear-gradient(to bottom, #fff 50%, #b8f9fd 50%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .link-btn {
      position: absolute;
      top: 50px;
      width: 100%;
      z-index: 10;
      box-sizing: border-box;
      padding: 0 15px;
    }

    .link-btn-item {
      width: 8%;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 14px;
      cursor: pointer;
      float: left;
      font-family: "mokuaiTitile";
      font-weight: 200;
      border-right: 1px solid #25406b;
    }

    .link-btn-item:hover {
      color: #ffcd35;
      //font-weight: bold;
    }

    /*.link-btn-item:first-child {
      color: #ffcd35;
      background-image: url("~@/assets/cloud2/index/top_select.png");
      background-position: center bottom;
      background-size: 100%;
      background-repeat: no-repeat;

    }*/

    .link-btn-item:nth-child(4) {
      margin-left: 50%;

    }

    .link-btn-item:last-child {
      float: right;
    }
  }

  .middle {
    height: 80px;
    line-height: 80px;
    display: flex;

  }

  .titleEach {
    // width: 100%;
    font-size: 2.78vh;
    font-family: customBlack;
    font-weight: normal;
    color: #daf1fe;
    position: absolute;
    top: 1.5vh;
    left: 2vw;
  }

  .btns{
    line-height: 40px;
    display: flex;
    padding: 0 3%;
    margin: 2vh 0;
    span{
      width: 10%;
      font-size: 14px;
      font-weight: bold;
      color: #0a8eff;
      cursor: pointer;
    }
    span.hover{
      color: #fdbf00;

    }
  }




  .bodyPart {
    width: 100%;
    height: calc(85vh);
    padding: 0 1.46vw;
    box-sizing: border-box;
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    align-content: space-between;
    padding-bottom: 1.2vh;
    box-sizing: border-box;
    overflow: hidden;

    .bodyTop {
      width: 100%;
      height: 48%;
      display: flex;
      justify-content: space-between;
    }

    .bodyBottom {
      width: 100%;
      height: 48%;
      display: flex;
      justify-content: space-between;
    }

    .mainBox {
      width: 23%;
      height: 90%;
      background: url('~@/assets/cloud2/mainBoxNew.png') no-repeat center;
      background-size: 100% 100%;
      box-sizing: border-box;
      overflow: auto;

      .boxTitle {
        width: 90%;
        height: 5vh;
        line-height: 5vh;
        margin-left: 2vw;
        margin-top: 2vh;
        font-size: 2vh;
        color: #fff;
      }

      #echarts1,
      #echarts2,
      #echarts3,
      #echarts4,
      #echarts5,
      #echarts6,
      #echarts7 {
        width: 100%;
        height: 75%;
        margin-top: 1vh;
      }
    }

  }
}

// 天气样式

.zhu-aside-top {
  flex-shrink: 0;
  height: 100%;
}

.zhu-left-aside .zhu-aside-top {
  background-image: url("/static/img/monitor/environmentMonitorServicePlatform_zhu/lfTopBG.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
}

.zhu-right-aside .zhu-aside-top {
  background-image: url("/static/img/monitor/environmentMonitorServicePlatform_zhu/rgTopBG.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
}

/* 天气 */
.weather-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  font-family: "Pang";
  color: white;
}

.weatherBox {
  position: relative;
}

.common-header {
  position: absolute;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-family: "Pang";
  top: -3vh;
  right: 2vw;
  font-size: 1.6vh;
}

.tem-title {
  font-size: 2vh;
  color: white;
}

.common-header-line {
  width: 80%;
  height: 2px;
  background: linear-gradient(270deg, rgba(87, 185, 250, 0.01), #51b7e3);
}

.weather-top {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tempature-value {
  font-size: 6vh;
}

.tempature-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.weather-value {
  font-size: 2.5vh;
}

.col-wrapper {
  display: flex;
}

.tempature-col {
  margin-top: 0.5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tempature-col:nth-child(2) {
  margin-left: 2vh;
}

.weather-center {
  margin-top: 0.5vh;
  display: flex;
  justify-content: space-between;
}

#maxDegree,
#minDegree,
.tem-one {
  font-size: 1.6vh;
}

.date,
.wather {
  font-size: 1.48vh;
}

.dayWather img {
  width: 2.87vh;
  height: 2.87vh;
  background: #fff;
}

.tempature {
  font-size: 1.6vh;
}

.wind-directory,
.rank {
  font-size: 1.39vh;
}

.info-top {
  font-weight: bold;
  font-size: 2.3vh;
}

.info-bttom {
  margin-top: 0.6vh;
  font-size: 1.8vh;
}

.weather-bottom {
  margin-top: 1.5vh;
  display: flex;
  justify-content: space-evenly;
}

.day-weather {
  flex-shrink: 0;
  width: calc(86% / 7);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #bfbfbf;
  font-size: 10px;
}

.day-weather:last-child {
  border: none;
}

.weather-img {
  font-size: 2vh;
}

.info-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.info-wrapper.short-line::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  height: 3vh;
  width: 2px;
  background-color: #bfbfbf;
  transform: translateY(-50%);
}

.el-table--striped .el-table__body tr.el-table__row--striped td {
  background-color: #0b154f !important;
}

.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #0b154f !important;
}

.el-table .cell {
  line-height: 3.77vh;
}

.el-table td,
.el-table th.is-leaf {
  border-bottom: 1px solid #063865;
}

.el-table th {
  color: #00eeff;
}

.el-table td:nth-of-type(1) {
  color: #00eeff;
}

.el-table td:nth-of-type(2) {
  color: #aaddff;
}

.el-table td:nth-of-type(3) {
  color: #f5c43f;
}

.el-table {
  height: 80%;
  overflow: hidden;
  overflow-y: scroll;
  background: transparent;

  th,
  tr {
    background-color: transparent !important;
  }

  tr:nth-of-type(2n) {
    background-color: #0b154f !important;
  }

  td,
  th {
    padding: 0 !important;
  }

  .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: #081137;
  }
}

.alarmBox {
  width: 90%;
  height: 27vh;
  margin: 2vh auto 0;
}

.alarmUl {
  width: 100%;
  height: 4vh;
  line-height: 4vh;
  color: #00eeff;
  text-align: center;
  display: flex;
  border-bottom: 1px solid #063865;
  font-size: 1.6vh;
  font-weight: bold;

  li {
    flex: 1;
  }
}

.alarmItem {
  width: 100%;
  height: 24vh;
  overflow: auto;
}

.itemUl {
  width: 100%;
  height: 4vh;
  line-height: 4vh;
  color: #00eeff;
  text-align: center;
  display: flex;
  border-bottom: 1px solid #063865;
  font-size: 1.6vh;
  font-weight: bold;

  li {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .color1 {
    color: #fff;
  }

  .color2 {
    color: #C7A13C;
  }
}

.itemMian {
  width: 100%;
  height: calc(100% - 6vh);
  padding: 0 1.2vw;
  box-sizing: border-box;
  color: #fff;
}

.itemMian ul,
.itemMian li {
  box-sizing: border-box;
}

.itemMian ul {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.itemMian ul::-webkit-scrollbar {
  display: none;
}

.itemMian li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 30%;
  font-size: 1.6vh;
  background: url('~@/assets/backOne/realTimeData.png') no-repeat center bottom;
  line-height: 3vh;
}

.itemMian li p {
  width: 100%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.itemTxt {
  padding-bottom: 1.5vh;
}


</style>